.text-spoiler {
    filter: blur(5px);
    transition: 0.3s -webkit-filter;
    -webkit-transform: translateZ(0);
}

.text-spoiler:hover {
    filter: blur(0px);
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 6px;
}
