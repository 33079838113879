/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../fonts/roboto-v20-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('../fonts/roboto-v20-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../fonts/roboto-v20-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-300.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-300italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-300italic.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-italic.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-600.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-600italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-600italic.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-700.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-700italic.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-800.woff') format('woff'); /* Modern Browsers */
}
/* open-sans-800italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local(''), url('../fonts/open-sans-v18-latin-800italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-800italic.woff') format('woff'); /* Modern Browsers */
}
